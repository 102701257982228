// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-advice-tsx": () => import("./../../../src/templates/Advice.tsx" /* webpackChunkName: "component---src-templates-advice-tsx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/BasicPage.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/Event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/News.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

