/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/* eslint-disable react/jsx-filename-extension */

import React from 'react';
//
import { PageWrapper } from 'arvesta-ui-library';

const theme = process.env.THEME_NAME;
const locale = process.env.DEFAULT_LOCALE;
const locales = process.env.ENABLED_LOCALES.split(',').map(loc => loc.trim());

/**
 * Implements Gatsby wrapPageElement(params).
 */
export const wrapPageElement = ({ element, props }) => (
  <PageWrapper {...props} theme={theme} defaultLocaleId={locale} enabledLocales={locales}>
    {element}
  </PageWrapper>
);

/**
 * Implements Gatsbt onClientEntry().
 */
export const onClientEntry = () => {
  if (process.env.NODE_ENV === `production`) {
    // Handle initialization of 3rd parties like GTM
  }
};
